<template>
  <div class="h-100">
    <div class="h-100" style="display: flex; flex-direction: column">
      <div
        class="d-flex align-items-center justify-content-between"
        style="padding: 0 2% 0 1.5%"
      >
        <p class="m-0 TitleTop">Loader</p>
        <div class="d-flex sectionTitle">
          <div class="d-flex align-items-center">
            <p
              class="m-0 mr-3"
              style="
                color: #1f1f28;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              "
            >
              Loader Color :
            </p>  

            <div style="display: flex;

padding: 4px 8px 4px 4px;
align-items: center;
gap: 6px;border-radius: 6px;
border: 1px solid #EDEDED;
background: var(--Base-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);">
  <input
              type="color"
              v-model="quizLoader.selectedLoaderColor"
              class="colorInput"
            />
            <input
              type="text"
              class=" ml-2 ColorTextInput input-border-style"
              style="
                color: #18191c;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.28px;
              "
              v-model="quizLoader.selectedLoaderColor"
            />
</div>

          
          </div>
        </div>
      </div>

      <div
        class="flex-grow-1"
        style="
          padding: 15px 2% 15px 1.5%;
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <div v-if="isLoading" style="min-height: 60vh">
          <component :is="`DefaultLoader`" :selectedColor="`#4d1b7e`" />
        </div>

        <div v-else>
          <div class="row px-1 py-2 " v-if="quizLoader">
            <div class="col-12 col-sm-6 col-lg-4 mb-3">
              <div
                class="loaderContaier cursor-pointer"
                :class="
                  quizLoader.selectedLoader == 'CustomQuizLoader'
                    ? 'selectedLoader'
                    : null
                "
              >
                <div>
                  <div
                    class="position-ralative"
                    @click="openImageModal"
                    style="
                      width: 100%;
                      border-top-left-radius: 12px;
                      border-top-right-radius: 12px;
                      background: #fff;
                    "
                    :style="
                      isCustomImageAdded ? 'height:200px;' : 'height:257px;'
                    "
                  >
                    <div style="position: absolute; top: 3%; right: 7%">
                      <svg
                        v-if="isCustomImageAdded"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                          fill="#0397CC"
                        />
                      </svg>
                     
                    </div>
                    <div
                      class="w-100 h-100 d-flex align-items-center justify-content-center"
                      style="
                        border-radius: 12px;
                        border: 1px solid #0397cc;
                        background: #e6f5fa;
                      "
                      v-if="!isCustomImageAdded"
                    >
                      <span
                        style="
                          color: #0397cc;
                          text-align: center;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                        "
                      >
                        <svg
                          class="mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M19.5 13H13.5V19H11.5V13H5.5V11H11.5V5H13.5V11H19.5V13Z"
                            fill="#0397CC"
                          />
                        </svg>
                        Add Custom Loader</span
                      >
                    </div>

                    <component
                      v-else
                      :is="`CustomQuizLoader`"
                      :getCustomLoaderImage="customLoaderMedia"
                      :selectedColor="quizLoader.selectedLoaderColor"
                    />
                  </div>
                  <div
                    class="p-2 d-flex justify-content-center align-items-center"
                    v-if="isCustomImageAdded"
                  >
                    <p
                      style="
                        color: #1f1f28;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                      "
                    >
                      Custom Loader
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-sm-6 col-lg-4 mb-3 "
              v-for="loader in loaderComponents"
              :key="loader.value"
            >
              <div
                class="loaderContaier cursor-pointer"
                :class="
                  loader.value == quizLoader.selectedLoader
                    ? 'selectedLoader'
                    : null
                "
                @click="updateSelectedLoader(loader.value)"
              >
                <div>
                  <div
                    class="position-relative"
                    style="
                      height: 200px;
                      width: 100%;
                      border-top-left-radius: 12px;
                      border-top-right-radius: 12px;
                      background: #fff;
                    "
                  >
                    <div style="position: absolute; top: 3%; right: 3%">
                      <svg
                        v-if="loader.value == quizLoader.selectedLoader"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                          fill="#0397CC"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                          fill="#D2D8E0"
                        />
                      </svg>
                    </div>
                    <component
                      :is="loader.value"
                      :selectedColor="quizLoader.selectedLoaderColor"
                    />
                  </div>
                  <div
                    class="p-2 d-flex justify-content-center align-items-center"
                  >
                    <p
                      style="
                        color: #1f1f28;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                      "
                    >
                      {{ loader.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-2 d-flex justify-content-end"
        style="
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
        "
      >
      <button
          class="btn text-white mx-2 mt-2"
          style="
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border-radius: 6px;
            background: #4d1b7e;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          "
          @click="saveData"
        >
          <span v-if="!isSaveLoading"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z" fill="white"/>
</svg>  Save Changes</span>
          <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>

    <ImageFileModal
      v-if="quizLoader"
      :selectedImage="quizLoader.customLoader.customLoaderImage"
      @selectedImageCallbackModal="updateSelectedCustomLoaderImage($event)"
      ref="ImageFileCompRef"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      isSaveLoading: false,
      selectedColor: `#4d1b7e`,
      quizLoader: null,
      loaderComponents: [
        {
          id: "defaultLoader",
          value: "DefaultLoader",
          name: "Default",
        },
        {
          id: "stairsLoader",
          value: "StairsLoader",
          name: "Stairs",
        },
        {
          id: "wavesLoader",
          value: "WavesLoader",
          name: "Waves",
        },
        {
          id: "doubleBounceLoader",
          value: "DoubleBounceLoader",
          name: "Double Bounce",
        },
        {
          id: "threeBounceLoader",
          value: "ThreeBounceLoader",
          name: "Three Bounce",
        },
      ],
    };
  },
  components: {
    ImageFileModal: () =>
      import(
        /* webpackChunkName: "ImageFileModal" */ "../component/ImageFileModal.vue"
      ),
    DefaultLoader: () =>
      import(
        /* webpackChunkName: "DefaultLoader" */ "@/components/LoaderComps/DefaultLoader.vue"
      ),
    StairsLoader: () =>
      import(
        /* webpackChunkName: "StairsLoader" */ "@/components/LoaderComps/StairsLoader.vue"
      ),
    WavesLoader: () =>
      import(
        /* webpackChunkName: "WavesLoader" */ "@/components/LoaderComps/WavesLoader.vue"
      ),
    DoubleBounceLoader: () =>
      import(
        /* webpackChunkName: "DoubleBounceLoader" */ "@/components/LoaderComps/DoubleBounceLoader.vue"
      ),
    ThreeBounceLoader: () =>
      import(
        /* webpackChunkName: "ThreeBounceLoader" */ "@/components/LoaderComps/ThreeBounceLoader.vue"
      ),
    CustomQuizLoader: () =>
      import(
        /* webpackChunkName: "CustomQuizLoader" */ "@/components/LoaderComps/CustomQuizLoader.vue"
      ),
  },

  methods: {
    async CopyToClipBoard() {
      await navigator.clipboard.writeText(this.generateLoaderLink);
      this.$toasted.show("Link Copied ", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
    async getLoaderData(token) {
      try {
        this.isLoading = true;
        let data = {
          api_token: token,
        };
        const response = await axios.get(`/api/getQuizLoader`, {
          params: data,
        });
        if (response.status == 200) {
          this.quizLoader = response.data.data;
        }
      } catch (error) {
        this.$toasted.show("Error Occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
          action: {
            class: "customToast",
          },
        });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    openImageModal() {
      this.$refs.ImageFileCompRef.openModalFunc();
    },
    updateSelectedCustomLoaderImage(data) {
      if (data !== "") {
        this.quizLoader.selectedLoader = "CustomQuizLoader";
        this.quizLoader.customLoader.enabled = true;
        this.quizLoader.customLoader.customLoaderImage = data;
      }
    },

    changeSelectedLoader(e) {
      if (e.target.checked) {
        this.quizLoader.selectedLoader = "CustomQuizLoader";
      } else {
        this.quizLoader.selectedLoader = "DefaultLoader";
      }
    },
    updateSelectedLoader(value) {
      if (value) {
        this.quizLoader.selectedLoader = value;
        if (value !== "CustomQuizLoader")
          this.quizLoader.customLoader.enabled = false;
      }
    },

    async saveData() {
      if (!this.quizLoader) return true;
      if (
        this.quizLoader.customLoader.enabled &&
        this.quizLoader.customLoader.customLoaderImage == ""
      ) {
        this.$toasted.show("Please select image for custom loader", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        return;
      }
      try {
        this.isSaveLoading = true;
        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
          quizLoader: this.quizLoader,
        };

        const response = await axios.post(`/addQuizLoader`, data);
        if (response.status == 200) {
          this.$toasted.show("Quiz Loader Link sucessfully generated !", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        this.$toasted.show("Error Occured!", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isSaveLoading = false;
      }
    },
  },

  computed: {
    ...mapGetters(["getQuizLoader"]),
    QuizLoaderData() {
      return this.getQuizLoader;
    },
    isCustomLoaderEnabled() {
      return this.quizLoader.customLoader.enabled;
    },
    isCustomImageAdded() {
      return this.quizLoader.customLoader.customLoaderImage != "";
    },
    customLoaderMedia() {
      return this.quizLoader.customLoader.customLoaderImage;
    },
    generateLoaderLink() {
      const { id: quizKey } = this.$route.params;
      const {
        customLoader,
        selectedLoader: defaultSelectedLoader,
        selectedLoaderColor,
      } = this.quizLoader;
      const { enabled: isCustomLoaderEnabled, customLoaderImage } =
        customLoader;

      const baseURL = `${process.env.VUE_APP_QUIZ_PREVIEW_URL}quiz/${quizKey}`;
      const selectedLoader = isCustomLoaderEnabled
        ? "CustomQuizLoader"
        : defaultSelectedLoader;
      let linkQuery = `?selectedLoader=${selectedLoader}&selectedLoaderColor=${encodeURIComponent(
        selectedLoaderColor
      )}`;

      if (isCustomLoaderEnabled && customLoaderImage) {
        linkQuery += `&customLoaderImage=${encodeURIComponent(
          customLoaderImage
        )}`;
      }

      return selectedLoader === "DefaultLoader"
        ? baseURL
        : `${baseURL}${linkQuery}`;
    },
  },
  mounted() {
    // this.quizLoader = this.getQuizLoader;
    this.getLoaderData(this.$route.params.id);
  },
};
</script>

<style scoped>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}

.loaderContaier {
  border-radius: 12px;
  border: 1px solid #ededed;
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  min-height: 250px;
  transition: all 0.3s ease;
}
.loaderContaier.selectedLoader {
  border-radius: 12px;
  border: 1px solid #0397cc !important;
}

.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px !important;
  height: 30px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px !important;
  margin-right: 5px;
  border-radius: 4px;
}
.colorInput::-webkit-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
.colorInput::-moz-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
</style>
